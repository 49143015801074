export const DELETE_ROLES = 'DELETE_ROLES';
export const DEACTIVATE_ROLES = 'DEACTIVATE_ROLES';
export const REACTIVATE_ROLES = 'REACTIVATE_ROLES';
export const CANT_DELETE_ROLES = 'CANT_DELETE_ROLES';
export const DELETE_DEFAULT_ROLES = 'DELETE_DEFAULT_ROLES';
export const DEACTIVATE_DEFAULT_ROLES = 'DEACTIVATE_DEFAULT_ROLES';
export const EDIT_DEFAULT_ROLES = 'EDIT_DEFAULT_ROLES';

export const rolesSort = {
  super_administrator: 1,
  course_administrator: 2,
  facilitator: 3,
  examiner: 4,
  learner: 5,
};

export const userTypeOption = [
  {
    value: 'super_administrator',
    label: 'Super Administrator',
  },
  {
    value: 'course_administrator',
    label: 'Course Administrator',
  },
  {
    value: 'facilitator',
    label: 'Facilitator',
  },
  {
    value: 'examiner',
    label: 'Examiner',
  },
  {
    value: 'learner',
    label: 'Learner',
  },
];

export const getStatusRoles = (status) => {
  const statusOption = { true: 'Active', false: 'Deactivated' };

  return statusOption[status];
};

export const onlyViewDetailRole = ['super_administrator'];
export const editableDefaultRole = ['course_administrator', 'facilitator', 'examiner'];
