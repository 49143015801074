import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css'
import 'animate.css';
import "../styles/globals.scss";

import Frame from 'components/Frame';
import store from "store";
import GoogleAnalytics from 'components/GoogleAnalytics';

function MyApp({ Component, pageProps }) {
  const lerero_lms = store.get('lerero_lms');
  if(lerero_lms) {
    lerero_lms.alreadyRefresh = false;
    store.set("lerero_lms", lerero_lms);
  }
  
  return (
	<>
	  <GoogleAnalytics />
      <Frame>
        <Component {...pageProps} />
      </Frame>
	</>
  );
}

// MyApp.getInitialProps = async (appContext) => {
//   const { ctx, router } = appContext;

//   // if (router.route.includes("/admin/") && !amsi) {
//   //   // Redirect to login page if not authenticated
//   //   ctx.res.writeHead(302, { Location: "/" });
//   //   ctx.res.end();
//   // }
//   return {};
// };

export default MyApp;
