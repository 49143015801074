import { useEffect } from 'react';
import { io } from 'socket.io-client';

const useSocket = (id, callback) => {
  useEffect(() => {
    const socket = io(process.env.NEXT_PUBLIC_API_URL);
    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });
    socket.on(id, () => {
      callback();
    });
    return () => {
      socket.disconnect();
    };
  }, []);
};

export default useSocket;
