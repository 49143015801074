import Link from "next/link";
import { useEffect, useState } from "react";

// ICONS
import {
  AnalyticSvg,
  LibrarySvg,
  DashboardSvg,
  QuestionSvg,
  DiscussionSvg,
  MarketplaceSvg,
  MarketplaceManagementSvg,
  CourseSvg,
  UserSvg,
  SettingSvg,
  ReportSvg,
  NotificationSvg,
  AchievementsSvg,
  MyProductsSvg,
  MyCourseSvg,
  DiscountManagementSvg
} from "public/sidebar";

// Styles
import { Font, MenuItem, CustomerAvatar } from "./Styles"

// Utils
import store from "store";
import Router from "next/router";
import moment from "moment";

// Service
import { getRolesDetail } from "services/api/UserManagement";
import { userTypeOption } from "components/UserManagement/Roles/Utils";

// Component
import Select, { components } from 'react-select';

const customStyles = {
  control: (provided) => ({
    ...provided,
    padding: '6.5px',
    marginTop: '2px',
    fontSize: '1.4rem',
    fontFamily: 'Titillium Web',
    borderColor: '#00000014',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '1.4rem',
    fontFamily: 'Titillium Web',
  }),
};

const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <div className="d-flex justify-content-between">
        <span>{children}</span>
        <img src="/survey/check.svg" />
      </div>
    </components.Option>
  );
};

const Sidebar = (props) => {

  const { activeTab, hideSidebar, setHideSidebar, user, hideSidebarMobile, setHideSidebarMobile, handleChangeRoleType } = props;
  const url = window.location.pathname;
  const [allowedDiscountManagement, setAllowedDiscountManagement] = useState([]);

  const checkActive = (reference) =>
  reference === activeTab && !url.includes('profile') ? "active-menu" : "";
  // const [roleTypeOption, setRoleTypeOption] = useState([])
  // const [selectedRoleType, setSelectedRoleType] = useState('')

  const sidebarStyle = () => {
    let data = {}
    if (hideSidebarMobile) data = {
      display: "block"
    }
    if (hideSidebar) data = {
      gridColumn:"sidebar-start/sidebar-end-small",
      whiteSpace: "nowrap"
    }
    return data
  }

  // const handleGetRolesDetail = async (id) => {
  //   const {data, success, errors} = await getRolesDetail({id})

  //   if(!success) {
  //     alert(errors ?? 'Something went wrong')
  //     return
  //   }

  //   setRoleTypeOption(data.roleTypes.filter((item) => item.activated).map((item) => {
  //     return {
  //       label: userTypeOption.filter((type) => type.value === item.roleTypeName)[0].label,
  //       value: item.roleTypeName
  //     }
  //   }))
  // }

  const sidebarMenuStyle = hideSidebar ? { overflowX: "hidden" } : {}

  const marketplaceLink = () => {
    const href = window.location.href
    if (href.includes('dev') || href.includes('localhost')) return 'https://dev.marketplace.lerero.com';
    if (href.includes('staging')) return 'https://staging.marketplace.lerero.com'
    return 'https://marketplace.lerero.com/'
  }

  const handleLogout = () => {
    store.remove("lerero_lms");
    Router.push({ pathname: "/" });
    setHideSidebarMobile(prev => !prev)
  };

  const handleChangePageMobile = () => {
    if (!hideSidebarMobile) return
    setTimeout(() => {
      setHideSidebarMobile(prev => !prev)
    }, 500);
    return true
  }

  const onClickSetting = () => {
    if (!handleChangePageMobile()) return
    return user?.roles === "LEARNER" ? Router.push('/profile/index_user') : Router.push('/profile') 
  }

  useEffect(() => {
    let user = localStorage.getItem('lerero_lms');
    user = JSON.parse(user);

    const allowedDiscount = process.env.NEXT_PUBLIC_ALLOWED_DISCOUNT
    ? process.env.NEXT_PUBLIC_ALLOWED_DISCOUNT.split(',')
    : [];
    

    if (allowedDiscount.includes(user.user.company)) {
      setAllowedDiscountManagement(true);
    } else {
      setAllowedDiscountManagement(false);
    }
    
  }, [])


  return (
    <div className="sidebar" style={sidebarStyle()}>
      <div className="double-chevron" onClick={() => setHideSidebar(prev => !prev)} style={{position: "relative"}}>
        <img role="button" draggable={false} style={{position: "absolute", right:"-20px"}} src="/double_chevron.svg"/>
      </div>

      {/* MOBILE */}
      <div className="only-mobile">
        <div onClick={() => setHideSidebarMobile(prev => !prev)} style={{position: "relative"}}>
          <img role="button" draggable={false} style={{position: "absolute", right:"-50px"}} src="/assignment/close.svg"/>
        </div>
        <div className="d-flex gap-4 align-items-center" style={{ paddingLeft:"31px", marginTop:"3rem", position:"relative" }}>
          <CustomerAvatar src={user.picture !== null ? user.picture?.path : '/navbar/avatar_dummy.svg'} />
          <div className="d-flex flex-column">
            <Font size="16px" weight="400">{user?.name}</Font>
            <Font size="12px" weight="400" color="#727272">{user?.email}</Font>
            <Font size="12px" weight="400" color="#969696">
              Last Login{" "}
              <b>{moment(user?.loggedInAt).format("DD MMM Y")}</b>
            </Font>
          </div>
        </div>
      </div>
      <div className="sidebar__menu" style={sidebarMenuStyle}>
        {props.user?.roleType === "learner" && (
          <>
            <Link href="/dashboard">
              <MenuItem onClick={handleChangePageMobile} hideSidebar={hideSidebar} className={`${checkActive("dashboard")}`}>
                <div><MyCourseSvg active={checkActive("dashboard")} /></div>
                <div className="title" style={{ marginLeft: "2px" }}>
                  My Courses
                </div>
              </MenuItem>
            </Link>
            <Link href="/discussion">
              <MenuItem onClick={handleChangePageMobile} hideSidebar={hideSidebar} className={`${checkActive("discussion")} align-items-center`}>
                <div>
                  <DiscussionSvg active={checkActive("discussion")} />
                </div>
                <div className="title">My Courses Discussions</div>
              </MenuItem>
            </Link>
            <Link href="/my-products">
              <MenuItem onClick={handleChangePageMobile} hideSidebar={hideSidebar} className={`${checkActive("my-products")} align-items-center`}>
                <div>
                  <MyProductsSvg active={checkActive("my-products")} />
                </div>
                <div className="title">My Products</div>
              </MenuItem>
            </Link>
            <a target="_blank" rel="noreferrer" href={marketplaceLink()} style={{ textDecoration:"none" }}>
              <MenuItem onClick={handleChangePageMobile} hideSidebar={hideSidebar} className={`${checkActive("marketplace")}`}>
                <div>
                  <MarketplaceSvg active={checkActive("marketplace")} />
                </div>
                <div className="title">Go to Marketplace</div>
              </MenuItem>
            </a>
            <Link href="/achievement">
              <MenuItem onClick={handleChangePageMobile} hideSidebar={hideSidebar} className={`${checkActive("achievement")}`}>
                <div>
                  <AchievementsSvg active={checkActive("achievement")} />
                </div>
                <div className="title">My Achievements</div>
              </MenuItem>
            </Link>
            <Link href="/learner/reports">
              <MenuItem onClick={handleChangePageMobile} hideSidebar={hideSidebar} className={`${checkActive("reports")}`}>
                <div>
                  <ReportSvg active={checkActive("reports")} />
                </div>
                <div className="title">Reports</div>
              </MenuItem>
            </Link>
          </>
        )}
        {(props.user?.roleType === "course_administrator" || props.user?.roleType === "super_administrator" || props.user?.roleType === "facilitator" || props.user?.roleType === "examiner")  && (
          <>
            {(props.user?.roleType === "course_administrator" || props.user?.roleType === "super_administrator") && (
              <Link href="/dashboard">
                <MenuItem onClick={handleChangePageMobile} hideSidebar={hideSidebar} className={`${checkActive("dashboard")}`}>
                  <div>
                    <DashboardSvg active={checkActive("dashboard")} />
                  </div>
                  <div className="title" style={{ marginLeft: "2px" }}>
                    Dashboard
                  </div>
                </MenuItem>
              </Link>
            )}
            {props.user?.roleType !== "examiner" && (
              <Link href={props.user?.roleType === "super_administrator" ? "/user-management/roles" : props.user?.roleType === "facilitator" ? "/user-management/learning-groups" : "/user-management"}>
                <MenuItem onClick={handleChangePageMobile} hideSidebar={hideSidebar} className={`${checkActive("user-management")}`}>
                  <div>
                    <UserSvg active={checkActive("user-management")} />
                  </div>
                  <div className="title">User Management</div>
                </MenuItem>
              </Link>
            )}
            <Link href="/course_management">
              <MenuItem onClick={handleChangePageMobile} hideSidebar={hideSidebar} className={`${checkActive("course_management")}`}>
                <div>
                  <CourseSvg active={checkActive("course_management")} />
                </div>
                <div className="title">Course Management</div>
              </MenuItem>
            </Link>
            {(props.user?.roleType === "course_administrator" || props.user?.roleType === "super_administrator") && (
              <Link href="/question_bank">
                <MenuItem onClick={handleChangePageMobile} hideSidebar={hideSidebar} className={`${checkActive("question_bank")}`}>
                  <div>
                    <QuestionSvg active={checkActive("question_bank")} />
                  </div>
                  <div className="title" style={{ marginLeft: "2px" }}>
                    Question Bank
                  </div>
                </MenuItem>
              </Link>
            )}
            {(props.user?.roleType === "course_administrator" || props.user?.roleType === "super_administrator") && (
              <Link href="/marketplace_management">
                <MenuItem onClick={handleChangePageMobile} hideSidebar={hideSidebar} className={`${checkActive("marketplace_management")}`}>
                  <div>
                    <MarketplaceManagementSvg active={checkActive("marketplace_management")} />
                  </div>
                  <div className="title">Marketplace Management</div>
                </MenuItem>
              </Link>
            )}
            {((props.user?.roleType === "course_administrator" || props.user?.roleType === "super_administrator") && allowedDiscountManagement) && (
              <Link href="/discount_management">
                <MenuItem onClick={handleChangePageMobile} hideSidebar={hideSidebar} className={`${checkActive("discount_management")}`}>
                  <div>
                    <DiscountManagementSvg active={checkActive("discount_management")} />
                  </div>
                  <div className="title">Discount Management</div>
                </MenuItem>
              </Link>
            )}
            {props.user?.roleType !== "examiner" && (
              <Link href="/discussion_admin">
                <MenuItem hideSidebar={hideSidebar} className={`${checkActive(url.includes("discussion_course") ? "discussion_course" : "discussion_admin")}`}>
                  <div className="logo">
                    <DiscussionSvg active={checkActive(url.includes("discussion_course") ? "discussion_course" : "discussion_admin")} />
                  </div>
                  <div className="title">Discussion Forum</div>
                </MenuItem>
              </Link>
            )}
            <Link href="/notification_management">
              <MenuItem onClick={handleChangePageMobile} hideSidebar={hideSidebar} className={`${checkActive("notification_management")}`}>
                <div>
                  <NotificationSvg active={checkActive("notification_management")} />
                </div>
                <div className="title">Notification Management</div>
              </MenuItem>
            </Link>
            <Link href="/reports/overview">
              <MenuItem onClick={handleChangePageMobile} hideSidebar={hideSidebar} className={`${checkActive("reports")}`}>
                <div>
                  <ReportSvg active={checkActive("reports")} />
                </div>
                <div className="title">Reports</div>
              </MenuItem>
            </Link>
          </>
        )}
      </div>

      {/* MOBILE */}
      <div className="only-mobile">
        <div  className="d-flex flex-column gap-5 only-mobile" style={{ marginTop:"5rem" }}>
          <div className="d-flex gap-3" style={{ paddingLeft:"31px" }} onClick={onClickSetting}>
            <img src="/navbar/settings.svg" alt="Settings" />
            <Font weight="400" size="16px">Settings</Font>
          </div>
          {/* <div 
            className="select-user-container" 
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="title">Select User Type</div>
            <Select
              styles={customStyles}
              options={roleTypeOption}
              menuPlacement="bottom"
              components={{
                Option,
              }}
              onChange={(e) => handleChangeRoleType(e.value)}
              value={roleTypeOption.filter((item) => item.value === selectedRoleType)}
            />
          </div> */}
          <button 
            className="button-no-hover"
            style={{ backgroundColor:"#FF579C", color: "white", border:"none", marginLeft:"2rem", width:"90%"}}
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>

    </div>
  );
};

export default Sidebar;
