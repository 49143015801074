import { lereroFetch as fetch } from 'utils/fetch';

export const getInAppNotifications = async ({ page, type }) => {
  let typeParams = '';

  if (type) {
    typeParams = `&type=${type}`;
  }

  const response = await fetch('get', `learner/notifications?page=${page}&limit=99999${typeParams}`);
  return response;
};

export const readNotification = async (payload) => {
  const response = await fetch('PATCH', `learner/notifications/read`, payload);
  return response;
};

export const unreadNotification = async ({ id }) => {
  const response = await fetch('PATCH', `learner/notifications/unread/${id}`);
  return response;
};

export const clearNotification = async () => {
  const response = await fetch('delete', `learner/notifications/delete`);
  return response;
};
