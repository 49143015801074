/**
 * Truncate string as long as the limit. If the string length is more than the limit, change the rest of the content to ...
 * @param string {string} string that will be truncated,
 * @param limit {number} number of limit of string length before it will be truncated
 */
const truncateString = ({ string, limit, withDot }) => {
  if (string.length <= limit) return string;
  return `${string.substring(0, limit)}${withDot ? '...' : ''}`;
};

export default truncateString;
