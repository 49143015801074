export const AnalyticSvg = (props) => {
  const { active = false } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? "1" : "0.42"}>
        <path
          fill={active ? "#066BFB" : "#313131"}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM7 16C7 16.55 7.45 17 8 17C8.55 17 9 16.55 9 16V11C9 10.45 8.55 10 8 10C7.45 10 7 10.45 7 11V16ZM12 17C11.45 17 11 16.55 11 16V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V16C13 16.55 12.55 17 12 17ZM15 16C15 16.55 15.45 17 16 17C16.55 17 17 16.55 17 16V14C17 13.45 16.55 13 16 13C15.45 13 15 13.45 15 14V16Z"
        />
      </g>
    </svg>
  );
};

export const DashboardSvg = (props) => {
  const { active = false } = props;
  // return (
  //   <span>
  //     {active ? (
  //       <img src="/global/icon_dashboard_active.svg" />
  //     ) : (
  //       <img src="/global/icon_dashboard.svg" />
  //     )}
  //     {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <g opacity={active ? "1" : "0.42"}>
  //        <path fill={active ? "#066BFB" : "#313131"} fillRule="evenodd" clipRule="evenodd" d="M2.54 0H5.92C7.33 0 8.46 1.15 8.46 2.561V5.97C8.46 7.39 7.33 8.53 5.92 8.53H2.54C1.14 8.53 0 7.39 0 5.97V2.561C0 1.15 1.14 0 2.54 0ZM2.54 11.4697H5.92C7.33 11.4697 8.46 12.6107 8.46 14.0307V17.4397C8.46 18.8497 7.33 19.9997 5.92 19.9997H2.54C1.14 19.9997 0 18.8497 0 17.4397V14.0307C0 12.6107 1.14 11.4697 2.54 11.4697ZM17.4601 0H14.0801C12.6701 0 11.5401 1.15 11.5401 2.561V5.97C11.5401 7.39 12.6701 8.53 14.0801 8.53H17.4601C18.8601 8.53 20.0001 7.39 20.0001 5.97V2.561C20.0001 1.15 18.8601 0 17.4601 0ZM14.0801 11.4697H17.4601C18.8601 11.4697 20.0001 12.6107 20.0001 14.0307V17.4397C20.0001 18.8497 18.8601 19.9997 17.4601 19.9997H14.0801C12.6701 19.9997 11.5401 18.8497 11.5401 17.4397V14.0307C11.5401 12.6107 12.6701 11.4697 14.0801 11.4697Z"/>
  //       </g>
  //     </svg> */}
  //   </span>
  // );
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? "1" : "0.42"} clipPath="url(#clip0_3226_1188)">
        <path
          fill={active ? "#066BFB" : "#313131"}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 8H8V4H4V8ZM10 20H14V16H10V20ZM8 20H4V16H8V20ZM4 14H8V10H4V14ZM14 14H10V10H14V14ZM16 4V8H20V4H16ZM14 8H10V4H14V8ZM16 14H20V10H16V14ZM20 20H16V16H20V20Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3226_1188">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const QuestionSvg = (props) => {
  const { active = false } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? "1" : "0.42"} clipPath="url(#clip0_3219_5238)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 4.5H18C19.11 4.5 20 5.39 20 6.5V17.5C20 18.61 19.11 19.5 18 19.5H2C0.89 19.5 0 18.61 0 17.5L0.01 6.5C0.01 5.39 0.89 4.5 2 4.5H6V2.5C6 1.39 6.89 0.5 8 0.5H12C13.11 0.5 14 1.39 14 2.5V4.5ZM8 4.5H12V2.5H8V4.5Z"
          fill={active ? "#066BFB" : "#313131"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3219_5238">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DiscussionSvg = (props) => {
  const { active = false } = props;
  return (
    <span>
      {active ? (
        <img src="/global/icon_discussion_active.svg" />
      ) : (
        <img src="/global/icon_discussion.svg" />
      )}
      {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity={active ? "1" : "0.42"}>
        <path fill={active ? "#066BFB" : "#313131"} fillRule="evenodd" clipRule="evenodd" d="M17.1798 4.41C17.1798 3.08 18.2598 2 19.5898 2C20.9198 2 21.9998 3.08 21.9998 4.41C21.9998 5.74 20.9198 6.82 19.5898 6.82C18.2598 6.82 17.1798 5.74 17.1798 4.41ZM13.3297 14.7593L16.2197 11.0303L16.1797 11.0503C16.3397 10.8303 16.3697 10.5503 16.2597 10.3003C16.1507 10.0503 15.9097 9.8803 15.6507 9.8603C15.3797 9.8303 15.1107 9.9503 14.9497 10.1703L12.5307 13.3003L9.75969 11.1203C9.58969 10.9903 9.38969 10.9393 9.18969 10.9603C8.99069 10.9903 8.81069 11.0993 8.68969 11.2593L5.73069 15.1103L5.66969 15.2003C5.49969 15.5193 5.57969 15.9293 5.87969 16.1503C6.01969 16.2403 6.16969 16.3003 6.33969 16.3003C6.57069 16.3103 6.78969 16.1893 6.92969 16.0003L9.43969 12.7693L12.2897 14.9103L12.3797 14.9693C12.6997 15.1393 13.0997 15.0603 13.3297 14.7593ZM15.4497 3.7803C15.4097 4.0303 15.3897 4.2803 15.3897 4.5303C15.3897 6.7803 17.2097 8.5993 19.4497 8.5993C19.6997 8.5993 19.9397 8.5703 20.1897 8.5303V16.5993C20.1897 19.9903 18.1897 22.0003 14.7897 22.0003H7.40069C3.99969 22.0003 1.99969 19.9903 1.99969 16.5993V9.2003C1.99969 5.8003 3.99969 3.7803 7.40069 3.7803H15.4497Z"/>
        </g>
      </svg> */}
    </span>
  );
};
export const LibrarySvg = (props) => {
  const { active = false } = props;
  return (
    <span>
      {active ? (
        <img src="/global/icon_library_active.svg" />
      ) : (
        <img src="/global/icon_library.svg" />
      )}
      {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity={active ? "1" : "0.42"}>
        <path fill={active ? "#066BFB" : "#313131"} fillRule="evenodd" clipRule="evenodd" d="M7.81 2H16.191C19.28 2 21 3.78 21 6.83V17.16C21 20.26 19.28 22 16.191 22H7.81C4.77 22 3 20.26 3 17.16V6.83C3 3.78 4.77 2 7.81 2ZM8.08 6.66V6.65H11.069C11.5 6.65 11.85 7 11.85 7.429C11.85 7.87 11.5 8.22 11.069 8.22H8.08C7.649 8.22 7.3 7.87 7.3 7.44C7.3 7.01 7.649 6.66 8.08 6.66ZM8.08 12.74H15.92C16.35 12.74 16.7 12.39 16.7 11.96C16.7 11.53 16.35 11.179 15.92 11.179H8.08C7.649 11.179 7.3 11.53 7.3 11.96C7.3 12.39 7.649 12.74 8.08 12.74ZM8.08 17.31H15.92C16.319 17.27 16.62 16.929 16.62 16.53C16.62 16.12 16.319 15.78 15.92 15.74H8.08C7.78 15.71 7.49 15.85 7.33 16.11C7.17 16.36 7.17 16.69 7.33 16.95C7.49 17.2 7.78 17.35 8.08 17.31Z"/>
        </g>
      </svg> */}
    </span>
  );
};
export const MarketplaceSvg = (props) => {
  const { active = false } = props;
  return (
    <span>
      {active ? (
        <img src="/global/icon_marketplace_active.svg" />
      ) : (
        <img src="/global/icon_marketplace.svg" />
      )}
      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <g opacity={active ? "1" : "0.42"}>
        <path fill={active ? "#066BFB" : "#313131"} d="M0 155.2C0 147.9 2.153 140.8 6.188 134.7L81.75 21.37C90.65 8.021 105.6 0 121.7 0H518.3C534.4 0 549.3 8.021 558.2 21.37L633.8 134.7C637.8 140.8 640 147.9 640 155.2C640 175.5 623.5 192 603.2 192H36.84C16.5 192 .0003 175.5 .0003 155.2H0zM64 224H128V384H320V224H384V464C384 490.5 362.5 512 336 512H112C85.49 512 64 490.5 64 464V224zM512 224H576V480C576 497.7 561.7 512 544 512C526.3 512 512 497.7 512 480V224z"/>
        </g>
      </svg> */}
    </span>
  );
};

export const MarketplaceManagementSvg = (props) => {
  const { active = false } = props;
  return (
    <span>
      {active ? (
        <img src="/global/icon_shop_bag_active.svg" />
      ) : (
        <img src="/global/icon_shop_bag.svg" />
      )}
    </span>
  );
};

export const DiscountManagementSvg = (props) => {
  const { active = false } = props;
  return (
    <span>
      {active ? (
        <img src="/global/icon_discount_management_active.svg" />
      ) : (
        <img src="/global/icon_discount_management.svg" />
      )}
    </span>
  );
};

export const CourseSvg = (props) => {
  const { active = false } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? "1" : "0.42"} clipPath="url(#clip0_3219_5213)">
        <path
          fill={active ? "#066BFB" : "#313131"}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3H21C22.1 3 23 3.9 23 5V17C23 18.1 22.1 19 21 19H16V20C16 20.55 15.55 21 15 21H9C8.45 21 8 20.55 8 20V19H3C1.9 19 1 18.1 1 17V5C1 3.9 1.9 3 3 3ZM4 17H20C20.55 17 21 16.55 21 16V6C21 5.45 20.55 5 20 5H4C3.45 5 3 5.45 3 6V16C3 16.55 3.45 17 4 17ZM18 8H9C8.45 8 8 8.45 8 9C8 9.55 8.45 10 9 10H18C18.55 10 19 9.55 19 9C19 8.45 18.55 8 18 8ZM9 12H18C18.55 12 19 12.45 19 13C19 13.55 18.55 14 18 14H9C8.45 14 8 13.55 8 13C8 12.45 8.45 12 9 12ZM7 8H5V10H7V8ZM5 12H7V14H5V12Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3219_5213">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const UserSvg = (props) => {
  const { active = false } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? "1" : "0.42"} clipPath="url(#clip0_3219_5227)">
        <path
          fill={active ? "#066BFB" : "#313131"}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.99 8C10.99 9.66 9.66 11 8 11C6.34 11 5 9.66 5 8C5 6.34 6.34 5 8 5C9.66 5 10.99 6.34 10.99 8ZM18.99 8C18.99 9.66 17.66 11 16 11C14.34 11 13 9.66 13 8C13 6.34 14.34 5 16 5C17.66 5 18.99 6.34 18.99 8ZM8 13C5.67 13 1 14.17 1 16.5V18C1 18.55 1.45 19 2 19H14C14.55 19 15 18.55 15 18V16.5C15 14.17 10.33 13 8 13ZM15.03 13.05C15.38 13.02 15.71 13 16 13C18.33 13 23 14.17 23 16.5V18C23 18.55 22.55 19 22 19H16.82C16.93 18.69 17 18.35 17 18V16.5C17 15.03 16.21 13.92 15.07 13.09C15.067 13.087 15.0639 13.083 15.0606 13.0787C15.053 13.0688 15.0439 13.057 15.03 13.05Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3219_5227">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SettingSvg = (props) => {
  const { active = false } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? "1" : "0.42"} clipPath="url(#clip0_3219_5238)">
        <path
          fill={active ? "#066BFB" : "#313131"}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5028 12C19.5028 12.34 19.4728 12.66 19.4328 12.98L21.5428 14.63C21.7328 14.78 21.7828 15.05 21.6628 15.27L19.6628 18.73C19.5428 18.95 19.2828 19.04 19.0528 18.95L16.5628 17.95C16.0428 18.34 15.4828 18.68 14.8728 18.93L14.4928 21.58C14.4628 21.82 14.2528 22 14.0028 22H10.0028C9.75277 22 9.54277 21.82 9.51277 21.58L9.13277 18.93C8.52277 18.68 7.96277 18.35 7.44277 17.95L4.95277 18.95C4.73277 19.03 4.46277 18.95 4.34277 18.73L2.34277 15.27C2.22277 15.05 2.27277 14.78 2.46277 14.63L4.57277 12.98C4.53277 12.66 4.50277 12.33 4.50277 12C4.50277 11.67 4.53277 11.34 4.57277 11.02L2.46277 9.37C2.27277 9.22 2.21277 8.95 2.34277 8.73L4.34277 5.27C4.46277 5.05 4.72277 4.96 4.95277 5.05L7.44277 6.05C7.96277 5.66 8.52277 5.32 9.13277 5.07L9.51277 2.42C9.54277 2.18 9.75277 2 10.0028 2H14.0028C14.2528 2 14.4628 2.18 14.4928 2.42L14.8728 5.07C15.4828 5.32 16.0428 5.65 16.5628 6.05L19.0528 5.05C19.2728 4.97 19.5428 5.05 19.6628 5.27L21.6628 8.73C21.7828 8.95 21.7328 9.22 21.5428 9.37L19.4328 11.02C19.4728 11.34 19.5028 11.66 19.5028 12ZM8.50277 12C8.50277 13.93 10.0728 15.5 12.0028 15.5C13.9328 15.5 15.5028 13.93 15.5028 12C15.5028 10.07 13.9328 8.5 12.0028 8.5C10.0728 8.5 8.50277 10.07 8.50277 12Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3219_5238">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ReportSvg = (props) => {
  const { active = false } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? "1" : "0.42"} clipPath="url(#clip0_3219_5247)">
        <path
          fill={active ? "#066BFB" : "#313131"}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 4H14.82C14.4 2.84 13.3 2 12 2C10.7 2 9.6 2.84 9.18 4H5C3.9 4 3 4.9 3 6V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM12 4C12.55 4 13 4.45 13 5C13 5.55 12.55 6 12 6C11.45 6 11 5.55 11 5C11 4.45 11.45 4 12 4ZM8 18H13C13.55 18 14 17.55 14 17C14 16.45 13.55 16 13 16H8C7.45 16 7 16.45 7 17C7 17.55 7.45 18 8 18ZM16 14H8C7.45 14 7 13.55 7 13C7 12.45 7.45 12 8 12H16C16.55 12 17 12.45 17 13C17 13.55 16.55 14 16 14ZM8 10H16C16.55 10 17 9.55 17 9C17 8.45 16.55 8 16 8H8C7.45 8 7 8.45 7 9C7 9.55 7.45 10 8 10Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3219_5247">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AchievementsSvg = (props) => {
  const { active = false } = props;
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g  opacity={active ? "1" : "0.42"} clipPath="url(#clip0_19596_1271)">
        <path
          fill={active ? "#066BFB" : "#313131"} 
          d="M16.6461 7.05361L18.0861 8.49361L13.2061 13.3736L9.91612 10.0836C9.72929 9.89636 9.47564 9.79113 9.21112 9.79113C8.94661 9.79113 8.69295 9.89636 8.50612 10.0836L2.50612 16.0936C2.11612 16.4836 2.11612 17.1136 2.50612 17.5036C2.89612 17.8936 3.52612 17.8936 3.91612 17.5036L9.20612 12.2036L12.4961 15.4936C12.8861 15.8836 13.5161 15.8836 13.9061 15.4936L19.4961 9.91361L20.9361 11.3536C21.2461 11.6636 21.7861 11.4436 21.7861 11.0036V6.70361C21.7961 6.42361 21.5761 6.20361 21.2961 6.20361H17.0061C16.5561 6.20361 16.3361 6.74361 16.6461 7.05361V7.05361Z" />
      </g>
      <defs>
        <clipPath id="clip0_19596_1271">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export const NotificationSvg = (props) => {
  const { active = false } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? "1" : "0.42"} clipPath="url(#clip0_20597_6743)">
        <path
          fill={active ? "#066BFB" : "#313131"}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0005 16.75L20.2905 18.04C20.9205 18.67 20.4805 19.75 19.5805 19.75H4.41054C3.52054 19.75 3.08054 18.67 3.71054 18.04L5.00054 16.75V10.75C5.00054 7.4 7.36054 4.6 10.5005 3.92V2.75C10.5005 1.92 11.1705 1.25 12.0005 1.25C12.8305 1.25 13.5005 1.92 13.5005 2.75V3.92C16.6405 4.6 19.0005 7.4 19.0005 10.75V16.75ZM13.9905 20.76C13.9905 21.86 13.1005 22.75 12.0005 22.75C10.9005 22.75 10.0105 21.86 10.0105 20.76H13.9905ZM11.0005 15.75V13.75H13.0005V15.75H11.0005ZM12.0005 11.75C12.5505 11.75 13.0005 11.3 13.0005 10.75V8.75C13.0005 8.2 12.5505 7.75 12.0005 7.75C11.4505 7.75 11.0005 8.2 11.0005 8.75V10.75C11.0005 11.3 11.4505 11.75 12.0005 11.75Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_20597_6743">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const MyProductsSvg = (props) => {
  const { active = false } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? "1" : "0.42"} clipPath="url(#clip0_20597_6744)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 7H16C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7H4C2.9 7 2 7.9 2 9V12C2 13.11 2.89 14 4 14H10V13C10 12.45 10.45 12 11 12H13C13.55 12 14 12.45 14 13V14H20C21.1 14 22 13.1 22 12V9C22 7.9 21.1 7 20 7ZM11 16C10.45 16 10 15.55 10 15H3.01V19C3.01 20.1 3.91 21 5.01 21H19C20.1 21 21 20.1 21 19V15H14C14 15.55 13.55 16 13 16H11ZM12 5C10.9 5 10 5.9 10 7H14C14 5.9 13.1 5 12 5Z"
          fill={active ? "#066BFB" : "#313131"}
        />
      </g>
      <defs>
        <clipPath id="clip0_20597_6744">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const MyCourseSvg = (props) => {
  const { active = false } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? "1" : "0.42"} clipPath="url(#clip0_20597_6745)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.06364 8.11625L10.4936 3.51625C11.0936 3.19625 11.8136 3.19625 12.4136 3.51625L21.9336 8.70624C22.2536 8.88624 22.4536 9.21625 22.4536 9.58625V15.9962C22.4536 16.5462 22.0036 16.9962 21.4536 16.9962C20.9036 16.9962 20.4536 16.5462 20.4536 15.9962V10.0862L12.4136 14.4762C11.8136 14.8062 11.0936 14.8062 10.4936 14.4762L2.06364 9.87625C1.37364 9.49624 1.37364 8.49625 2.06364 8.11625ZM4.45364 15.9862V13.1762L10.4936 16.4762C11.0936 16.8062 11.8136 16.8062 12.4136 16.4762L18.4536 13.1762V15.9862C18.4536 16.7162 18.0536 17.3962 17.4136 17.7462L12.4136 20.4762C11.8136 20.8062 11.0936 20.8062 10.4936 20.4762L5.49364 17.7462C4.85364 17.3962 4.45364 16.7162 4.45364 15.9862Z"
          fill={active ? "#066BFB" : "#313131"}
        />
      </g>
      <defs>
        <clipPath id="clip0_20597_6745">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
