import styled from "styled-components"

export const Logo = styled.div`
  grid-column: sidebar-start / sidebar-end;
  padding: 15px;
  position: sticky;
  top: 0;
  padding-top: 10px;
  background-color: white;
  z-index: 9;
`

export const MenuItem = styled.div`
  display: flex;
  gap: 12px;
  padding: 12px 0px 12px 0;
  padding-left: ${({ hideSidebar }) => hideSidebar ? "15px" : "31px"};

  transition: all .2s;

  ${({ hideSidebar }) => {
    if (hideSidebar) return ``
  }}

  font-weight: 600;
  font-size: 16px;
  font-family: 'Titillium Web';
  color: #03022982;
  cursor: pointer;

  &:hover {
    background-color: #0000000a;
  }
  
  @media (max-height: 650px) {
    padding: 7px 0px 7px 0px;
    padding-left: ${({ hideSidebar }) => hideSidebar ? "15px" : "31px"};
  }
`
export const Font = styled.div`
  font-family: "Titillium Web";
  font-size: ${({ size }) => size || "2rem"};
  font-weight: ${({ weight }) => weight || 600};
  color: ${({ color }) => color || "black"};
`;

export const CustomerAvatar = styled.div`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;