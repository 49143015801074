import { lereroFetch as fetch, lereroFetchFile } from 'utils/fetch';

// Roles Management
//#region
// Get all role
export const getRolesList = async ({ page, limit = 5, search }) => {
  const response = await fetch('get', `roles?page=${page}&limit=${limit}&search=${search}`);
  return response;
};

// Create new role
export const createNewRole = async (payload) => {
  const response = await fetch('post', `roles`, payload);
  return response;
};

// Update role
export const updateRoleDetail = async ({ id, payload }) => {
  const response = await fetch('PATCH', `roles/edit/${id}`, payload);
  return response;
};

// get user by role name
export const getUserByRoleName = async ({ roleName }) => {
  const response = await fetch('get', `users?page=1&limit=1&roleName=${roleName}`);
  return response;
};

// delete role
export const deleteRole = async ({ id }) => {
  const response = await fetch('PATCH', `roles/deletion`, { deleted: true, roleIds: [...id] });
  return response;
};

// activated deactivate role
export const changeRoleActivation = async ({ id, activated }) => {
  const response = await fetch('PATCH', `roles/activation`, { activated, roleIds: [...id] });
  return response;
};

//get role detail
export const getRolesDetail = async ({ id }) => {
  const response = await fetch('get', `roles/${id}`);
  return response;
};

export const updateRoleType = async ({ payload }) => {
  const response = await fetch('GET', `auth/switch-role-type/${payload.roleType}`);
  return response;
};

export const getRoleListForUserTab = async () => {
  const response = await fetch('GET', `roles/select/list`);
  return response;
};

//#endregion

//User
//#region
//get list user
export const getUsersList = async ({ page, limit = 5, keyword, roleName, filterStatus }) => {
  let filterStatusQuery = '';

  if (filterStatus.length > 0) {
    filterStatusQuery = `&status=${filterStatus.join('&status=')}`;
  }

  const response = await fetch(
    'get',
    `users?page=${page}&limit=${limit}&filter=${keyword}&sort=-createdAt&roleName=${roleName}${filterStatusQuery}`
  );
  return response;
};

// get user detail
export const getUserDetail = async (id) => {
  const response = await fetch('get', `users/${id}`);
  return response;
};

//create user
export const createNewUser = async (formData) => {
  const response = await lereroFetchFile('post', 'users', formData);
  return response;
};

//delete user
export const deleteUser = async (id) => {
  const response = await fetch('delete', `users/${id}`);
  return response;
};

//resend activation link
export const resendActivationLink = async (id) => {
  const response = await fetch('get', `users/${id}/send-activation-link`);
  return response;
};

// update user detail
export const updateUser = async (id, payload) => {
  const response = await fetch('PATCH', `users/${id}`, payload);
  return response;
};
//#endregion

// bulk action
export const bulkResendActivationLink = async (payload) => {
  const response = await fetch('post', 'users/send-activation-link', payload);
  return response;
};

export const bulkDeleteUser = async (payload) => {
  const response = await fetch('delete', 'users', payload);
  return response;
};

export const bulkUserActivation = async (payload) => {
  const response = await fetch('PATCH', 'users/status', payload);
  return response;
};

export const bulkAssignCourse = async (payload) => {
  const response = await fetch('post', 'users/assign-courses', payload);
  return response;
};

export const bulkUnassignCourse = async (payload) => {
  const response = await fetch('post', 'users/unassign-courses', payload);
  return response;
};

export const bulkAssignLearningGroup = async (payload) => {
  const response = await fetch('post', 'users/assign-groups', payload);
  return response;
};

export const bulkUnassignLearningGroup = async (payload) => {
  const response = await fetch('post', 'users/unassign-groups', payload);
  return response;
};
