import store from "store";
import Router from "next/router";

export async function lereroDownloadFile(method="GET", api_url, body=null, fileName="downloaded-file") {
  try {
    const blob = await lereroFetch(method, api_url, body, true, "blob");

    if (blob instanceof Blob) {
      const blobUrl = URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = fileName; // Specify the desired filename
      anchor.click();

      URL.revokeObjectURL(blobUrl);
    } else {
      // Handle the case where lereroFetch did not return a Blob
      console.error("File download failed: Unexpected response");
    }
    return { success: true, error:"" }
  } catch (error) {
    // Handle errors, e.g., network issues or failed fetch requests
    console.error("File download failed:", error.message);
    return { success: false, error:error?.message || "Failed" }
  }
}
export async function refreshToken() {
  try {
    const lerero_lms = store.get("lerero_lms");
    if(!lerero_lms.alreadyRefresh) {
      lerero_lms.alreadyRefresh = true
      store.set("lerero_lms", lerero_lms);

      const response = await fetch(process.env.NEXT_PUBLIC_API_URL + 'auth/refresh', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + lerero_lms.refreshToken,
          AllowedOrigin: "*",
        }
      });

      const res = await response.json();
      const errorCode = res.statusCode;
      
      // If refresh token is expired, redirect to login page
      if (errorCode === 401) {
        store.remove("lerero_lms");
        Router.push({ pathname: "/" });
      }

      if (res.success) {
        // Refresh the access and refresh token
        lerero_lms.alreadyRefresh = false;
        lerero_lms.accessToken = res?.data?.accessToken;
        lerero_lms.refreshToken = res?.data?.refreshToken;
        store.set("lerero_lms", lerero_lms);

        return true;
      } else {
        throw new Error('Refresh token failed');
      }
    } else {
      return true;
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
}

export async function lereroFetch(
  fetchType,
  const_api_url,
  objData,
  putAuthToken = true,
  responseType = "",
  abortSignal
) {
  let header = {
    "Content-Type": "application/json",
    AllowedOrigin: "*",
  };
  if (putAuthToken) {
    let lerero_lms = store.get("lerero_lms");
    if (lerero_lms && lerero_lms?.accessToken) {
      header = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + lerero_lms?.accessToken,
        AllowedOrigin: "*",
      };
    }
  }
  let resp = [];
  const reqInit = {
    method: fetchType,
    headers: header,
    signal: abortSignal
  }

  try {
    if (
      fetchType.toUpperCase() === "GET" ||
      (fetchType.toUpperCase() === "DELETE" && !objData)
    ) {
      resp = await fetch(process.env.NEXT_PUBLIC_API_URL + const_api_url, reqInit);
    } else {
      resp = await fetch(process.env.NEXT_PUBLIC_API_URL + const_api_url, {
        ...reqInit,
        body: JSON.stringify(objData),
      });
    }
    if (responseType === "blob") {
      let res = await resp.blob();
      return res
    }
    let res = await resp.json();
    const errorCode = res.statusCode;
    const errorMessage = res.errors;

    if (errorCode === 403 && errorMessage === "Forbidden resource") {
      const dataLms = store.get('lerero_lms') ?? '';
      const alreadyError = store.get('already-error') ?? false;
  
      const removeAlert = () => {
        const originalAlert = window.alert;
        window.alert = function () {};
        setTimeout(() => {
          window.alert = originalAlert;
        }, 10);
      };

      const handleDoNotHaveAccess = () => {
        store.set('already-error', true);
        store.set('is-dont-have-access', true);
        removeAlert();
      }

      const specialRoleCondition =
        dataLms && (dataLms.user.roleType === 'facilitator' || dataLms.user.roleType === 'examiner');

      if (
        !alreadyError &&
        ((specialRoleCondition && Router.asPath === '/course_management') || Router.asPath === '/dashboard')
      ) {
        Router.reload();
        handleDoNotHaveAccess();
        return res;
      }

      if (specialRoleCondition) Router.push({ pathname: '/course_management' });
      else Router.push({ pathname: '/dashboard' });

      handleDoNotHaveAccess();
      return res;
    }

    if (errorCode === 401 && const_api_url !== 'auth/login') {
      const statusRefreshToken = await refreshToken();
      if(statusRefreshToken) {
        // Refetch
        if(objData) return lereroFetch(fetchType, const_api_url, objData)
        else return lereroFetch(fetchType, const_api_url)
      }
      // store.remove("lerero_lms");
      // Router.push({ pathname: "/" });
    }
    // else if (errorCode === 500) {
    //   Router.push({ pathname: "/500" });
    // }
    return res;
  } catch (e) {
    // Handle the error and check for abort signal
    if (e.name === 'AbortError') {
      return {
        status: false,
        message: 'Request aborted',
        abort: true
      };
    } else {
      return {
        status: false,
        message: e.message,
        abort: false
      };
    }
  }
}

export async function lereroFetchFile(
  fetchType,
  const_api_url,
  objData = {},
  putAuthToken = true,
  signal
) {
  let header = {
    "Content-Type":
      "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW; application/octet-stream ",
  };
  if (putAuthToken) {
    let lerero_lms = store.get("lerero_lms");
    if (lerero_lms && lerero_lms?.accessToken) {
      header = {
        Authorization: "Bearer " + lerero_lms?.accessToken,
      };
    }
  }
  try {
    let resp = await fetch(process.env.NEXT_PUBLIC_API_URL + const_api_url, {
      method: fetchType,
      headers: header,
      body: objData,
      signal: signal,
    });

    let res = await resp.json();
    const errorCode = res.statusCode;
    const errorMessage = res.errors;
    
    if (errorCode === 403 && errorMessage === "Forbidden resource") {
      store.set('is-dont-have-access', true);
      const dataLms = store.get('lerero_lms') ?? '';

      const specialRoleCondition =
        dataLms && (dataLms.user.roleType === 'facilitator' || dataLms.user.roleType === 'examiner');

      if ((specialRoleCondition && Router.asPath === '/course_management') || Router.asPath === '/dashboard') {
        Router.reload();

        return;
      }

      if (specialRoleCondition) Router.push({ pathname: '/course_management' });
      else Router.push({ pathname: '/dashboard' });

      return;
    }
    if (errorCode === 401) {
      const statusRefreshToken = await refreshToken();
      if(statusRefreshToken) {
        // Refetch
        return lereroFetchFile(fetchType, const_api_url, objData)
      }
      // store.remove("lerero_lms");
      // Router.push({ pathname: "/" });
    }
    // else if (errorCode === 500) {
    //   Router.push({ pathname: "/500" });
    // }
    return res;
  } catch (error) {
    // Handle the error and check for abort signal
    if (error.name === 'AbortError') {
      return {
        status: false,
        message: 'Request aborted',
        abort: true
      };
    } else {
      return {
        status: false,
        message: error.message,
        abort: false
      };
    }
  }
}


