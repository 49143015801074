import { useState, useEffect } from 'react';
import styled from 'styled-components';
import store from 'store';
import Router, { useRouter } from 'next/router';
import moment from 'moment';

// Styles
import { CustomerAvatar } from './Styles';

// Service
import { getRolesDetail } from 'services/api/UserManagement';
import { userTypeOption } from 'components/UserManagement/Roles/Utils';

// Component
import Select, { components } from 'react-select';
import { lereroFetch } from 'utils/fetch';
import NotificationWrapper from 'components/InAppNotification/NotificationWrapper';
import { getInAppNotifications } from 'services/api/InAppNotification';
import generateTotalNotification from 'utils/calculate_notifications';
import useSocket from 'utils/hooks/useSocket';

const customStyles = {
	control: (provided) => ({
		...provided,
		padding: '6.5px',
		marginTop: '2px',
		fontSize: '1.4rem',
		fontFamily: 'Titillium Web',
		borderColor: '#00000014',
	}),
	option: (provided) => ({
		...provided,
		fontSize: '1.4rem',
		fontFamily: 'Titillium Web',
	}),
};

const Option = ({ children, ...props }) => {
	return (
		<components.Option {...props}>
			<div className="d-flex justify-content-between">
				<div clasName="p-3">{children}</div>
				<img src="/survey/check.svg" />
			</div>
		</components.Option>
	);
};

const Navbar = (props) => {
	const { setHideSidebarMobile, handleChangeRoleType } = props;
	const [open, setOpen] = useState(false);
	const [role, setRole] = useState('');
	const [isExtended, setIsExtended] = useState(false);
	const [roleTypeOption, setRoleTypeOption] = useState([]);
	const [selectedRoleType, setSelectedRoleType] = useState('');
	const [userData, setUserData] = useState({
		fullName: '',
		email: '',
	});

	const [notificationData, setNotificationData] = useState({
		totalData: 0,
		totalUnread: 0,
		list: [],
	});
	const [isNotificationOpened, setIsNotificationOpened] = useState(false);
	const [loadingGetNotification, setLoadingGetNotification] = useState(true);

	const handleLogout = () => {
		store.remove('lerero_lms');
		Router.push({ pathname: '/' });
	};

	const handleGetRolesDetail = async (id) => {
		const { data, success, errors } = await getRolesDetail({ id });

		// if(!success) {
		//   alert(errors ?? 'Something went wrong')
		//   return
		// }

		if (data && success) {
			setRoleTypeOption(
				data.roleTypes
					.filter((item) => item.activated)
					.map((item) => {
						return {
							label: userTypeOption.filter((type) => type.value === item.roleTypeName)[0].label,
							value: item.roleTypeName,
						};
					})
			);
		}
	};

	async function getUseById(id) {
		const response = await lereroFetch('GET', `users/${id}`);
		if (response.success) {
			const dataUser = store.get('lerero_lms');
			dataUser.user.timezone = response?.data?.timezone || '';
			store.set('lerero_lms', dataUser);
			setUserData({
				...userData,
				fullName: response.data.firstName + ' ' + response.data.lastName,
				email: response.data.email,
			});
		}
	}

	const getInAppNotificationsList = async ({ type = '' }) => {
		setLoadingGetNotification(true);
		const { data, success } = await getInAppNotifications({ page: 1, type });
		if (success) {
			setNotificationData({
				totalUnread: data.totalUnread,
				list: data.data,
				totalData: data.meta.totals,
			});
		}
		setLoadingGetNotification(false);
	};

	useEffect(() => {
		let user = localStorage.getItem('lerero_lms');
		user = JSON.parse(user);
		setRole(user.user.roles);
		handleGetRolesDetail(user.user.roleId);
		setSelectedRoleType(user.user.roleType);
		getInAppNotificationsList({});
		// getUseById(user.user.sub)
	}, []);

	const router = useRouter();
	const route = router.route;

	useEffect(() => {
		getUseById(props.user.sub);
	}, [route]);

	useSocket('notification', () => {
		getInAppNotificationsList({});
	});

	return (
		<div className="navbar">
			{/* NAVBAR WEB DISPLAY */}
			<div className="navbar__section">
				{role === 'LEARNER' && (
					<>
						<div className="button-navbar" onClick={() => Router.push('/achievement?activeTab=Badges')}>
							<img draggable={false} src="/navbar/medal-1.svg" alt="medal-1" title="Badges" />
						</div>
						<div className="button-navbar" onClick={() => Router.push('/achievement?activeTab=Leaderboards')}>
							<img draggable={false} src="/navbar/medal-2.svg" alt="medal-2" title="Leaderboards" />
						</div>
					</>
				)}
			</div>
			<div className="navbar__section">
				<div
					className="notification__icon"
					onClick={() => {
						setIsNotificationOpened(!isNotificationOpened);
						if (!isNotificationOpened) {
							getInAppNotificationsList({});
						}
					}}
				>
					<img draggable={false} src="/navbar/notification.svg" alt="notification" title="Notifications" />
					{notificationData.totalUnread > 0 ? (
						<span className="counter">{generateTotalNotification(notificationData.totalUnread)}</span>
					) : null}
				</div>
				<div
					className="account-navbar"
					onClick={() => {
						setOpen(!open);
					}}
				>
					<CustomerAvatar src={props.user.picture !== null && props.user.picture.path !== "undefined" ? props.user.picture?.path : '/navbar/avatar_dummy.svg'} />
					<div className="text">
						<div className="name">{userData.fullName}</div>
						<div className="organization">{userData.email}</div>
					</div>
					<img draggable={false} style={{ rotate: open ? '180deg' : '' }} src="/chevron_down.svg" alt="Chevron Down" />
					{open && (
						<div className="account-setting">
							<p>
								Last Login <b>{moment(props.user?.loggedInAt).format('DD MMM Y')}</b>
							</p>
							<div
								className="select-user-container"
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								<div className="title">Select User Type</div>
								<Select
									styles={customStyles}
									options={roleTypeOption}
									menuPlacement="bottom"
									components={{
										Option,
									}}
									onChange={(e) => handleChangeRoleType(e.value)}
									value={roleTypeOption.filter((item) => item.value === selectedRoleType)}
								/>
							</div>
							<hr />
							<div
								className="account-setting-container cursor-pointer"
								onClick={() =>
									props.user?.roles === 'LEARNER' ? Router.push('/profile/index_user') : Router.push('/profile')
								}
							>
								<img src="/navbar/settings.svg" alt="Settings" />
								<span>Settings</span>
							</div>
							<hr />
							<button onClick={handleLogout}>Logout</button>
						</div>
					)}
				</div>
			</div>

			{/* NAVBAR MOBILE DISPLAY */}
			<img
				className="mobile"
				role="button"
				src="/navbar/burger.svg"
				draggable={false}
				onClick={() => setHideSidebarMobile((prev) => !prev)}
			/>

			<img
				className="mobile"
				role="button"
				src="/navbar/logo.svg"
				style={{ justifySelf: 'center', marginLeft: '3rem' }}
				draggable={false}
			/>
			<div className="navbar__mobile-section " style={{ justifySelf: 'self-end' }}>
				{role === 'LEARNER' && (
					<>
						<div
							className="notification__icon"
							onClick={() => {
								setIsNotificationOpened(!isNotificationOpened);
								if (!isNotificationOpened) {
									getInAppNotificationsList({});
								}
							}}
						>
							<img draggable={false} src="/navbar/notification.svg" alt="notification" title="Notifications" />
							{notificationData.totalUnread > 0 ? (
								<span className="counter">{generateTotalNotification(notificationData.totalUnread)}</span>
							) : null}
						</div>
						<div className="button-navbar" onClick={() => Router.push('/achievement?activeTab=Badges')}>
							<img role="button" src="/navbar/medal-1.svg" draggable={false} alt="Medal-1" />
						</div>
						<div className="button-navbar" onClick={() => Router.push('/achievement?activeTab=Leaderboards')}>
							<img role="button" src="/navbar/medal-2.svg" draggable={false} alt="Medal-2" />
						</div>
					</>
				)}
			</div>

			{/* NOTIFICATION AREA */}
			{isNotificationOpened ? (
				<NotificationWrapper
					list={notificationData.list}
					total={notificationData.totalData}
					getList={getInAppNotificationsList}
				></NotificationWrapper>
			) : null}
		</div>
	);
};

export default Navbar;
